/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:a1802ffd-586c-401e-ae28-b3160d756b35",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_l60Z9bzRg",
    "aws_user_pools_web_client_id": "46middcerfoful8oh9i737hj4q",
    "oauth": {
        "domain": "auth.dev.hansefit.de.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://vpp.dev.hansefit.de",
        "redirectSignOut": "https://vpp.dev.hansefit.de",
        "responseType": "token"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "GIVEN_NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "hansefit-dev-pimcore-dam-20210804075610066400000001",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "deepl",
            "endpoint": "https://wg7we9emg8.execute-api.eu-central-1.amazonaws.com/staging",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
