import { styled } from '@mui/material';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { useIntl } from 'react-intl';
import { makeStyles } from 'tss-react/mui';

import Theme from '@/App/Theme/Theme';

import FigmaDesignTokens from '../../../../design/design-tokens.json';
import LabelDisplayedRows from '../Footer/LabelDisplayRows';
import { useDataGridLocaleText } from '../hooks/useDataGridLocalText';

type CustomGridProps = {
  hideColumns?: boolean;
};
export const useHeaderTableStyles = makeStyles()(theme => ({
  checkIn: {
    background: theme.palette.primary.light,
    padding: '8px 16px',
    borderRadius: 10,
    display: 'flex',
    gap: 3,
    alignItems: 'center',
  },
}));

export const useToolbarStyles = makeStyles()(() => ({
  toolbarButton: {
    color: FigmaDesignTokens.Grey[500],
    fontWeight: 500,
    fontFamily: 'Roboto',
    fontSize: 16,
    '& .MuiButton-root': {
      color: FigmaDesignTokens.Grey[500],
      fontWeight: 500,
      fontFamily: 'Roboto',
      fontSize: 16,
      textTransform: 'initial',
      height: 40,
    },
    '& p': {
      color: FigmaDesignTokens.Grey[500],
      fontWeight: 500,
      fontFamily: 'Roboto',
      fontSize: 16,
      textTransform: 'initial',
    },
    '& .MuiButton-startIcon': {
      marginRight: 4,
      '& .material-icons': {
        width: 24,
        height: 24,
      },
    },
  },
  switchToolbar: {
    '& p': {
      fontSize: 16,
      fontWeight: 400,
    },
  },
}));

const DataGridTable = styled(DataGridPro)<CustomGridProps>(props => ({
  flexGrow: 1,
  border: 'none',
  '--DataGrid-containerBackground': FigmaDesignTokens.White,
  '--DataGrid-rowBorderColor': FigmaDesignTokens.Grey[100],
  '--DataGrid-overlayHeight': '400px',

  '.MuiDataGrid-row.MuiDataGrid-row--firstVisible': {
    '--rowBorderColor': FigmaDesignTokens.Grey[100],
  },

  '.MuiPaper-root': {
    backgroundColor: FigmaDesignTokens.Red[500],
  },

  '.MuiDataGrid-toolbarContainer': {
    alignContent: 'center',
  },
  '.MuiDataGrid-row': {
    '&:hover': {
      backgroundColor: FigmaDesignTokens.Grey[100], //remove hover effect if requested
    },
  },
  '.MuiDataGrid-footerContainer': {
    border: 'none',
  },

  '.MuiDataGrid-columnHeaderTitle': {
    color: FigmaDesignTokens.Grey[500],
    fontFamily: 'Roboto-Medium',
    textTransform: 'uppercase',
    fontSize: 14,
  },
  '& .MuiDataGrid-columnHeaders': {
    borderBottomColor: FigmaDesignTokens.Grey[100],
    borderBottomWidth: '2px',
    display: `${props.hideColumns ? 'none' : 'inherit'}`,
  },
  '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '.MuiDataGrid-cell': {
    borderBottom: `2px solid ${FigmaDesignTokens.Grey[100]}`,
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    alignContent: 'center',

    '& p.bold': {
      fontSize: '14px',
      fontFamily: 'Roboto-Bold',
    },
    '& .textPrimaryColor': {
      color: Theme.palette.text.primary,
    },
    '& .iconCell': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .actionCell': {
      cursor: 'pointer',
    },
    '& .boldCell': {
      fontWeight: 'bold',
      fontSize: 14,
    },
    '&.groupCell': {
      backgroundColor: FigmaDesignTokens.Grey[200],
    },
    '&.noPadding': {
      padding: 0,
    },
  },
  '& .MuiDataGrid-pinnedColumns': {
    background: FigmaDesignTokens.White,
    boxShadow: 'none',
  },
  '& .MuiDataGrid-pinnedColumnHeaders': {
    background: FigmaDesignTokens.White,
    boxShadow: 'none',
  },
  '& .cancelRow': {
    opacity: 0.5,
    color: FigmaDesignTokens.Grey[500],
  },
  '& .cancelStatusRow': {
    color: FigmaDesignTokens.Grey[500],
  },
  '.MuiBadge-badge': {
    backgroundColor: FigmaDesignTokens.Blue[800],
    minHeight: '13px',
    minWidth: '13px',
    height: '13px',
    width: '13px',
    fontSize: '10px',
    left: '5px',
    top: '4px',
  },
  '.MuiCheckbox-root svg': {
    fill: FigmaDesignTokens.Grey[300],
  },
  '.MuiCheckbox-root.Mui-disabled svg': {
    fill: FigmaDesignTokens.Grey[50],
  },
  '.MuiCheckbox-root.Mui-checked svg': {
    fill: FigmaDesignTokens.Blue[800],
  },
  '.MuiInputBase-root': {
    height: '40px',
    marginRight: '8px',
  },
}));

export const CustomDataGrid = (props: DataGridProProps & CustomGridProps) => {
  const dataGridLocalText = useDataGridLocaleText();
  const { formatMessage } = useIntl();

  return (
    <DataGridTable
      localeText={dataGridLocalText}
      pageSizeOptions={[10, 25, 50, 100]}
      {...props}
      slotProps={{
        filterPanel: {
          sx: {
            padding: '8px',

            '.MuiDataGrid-filterFormDeleteIcon': {
              alignSelf: 'center',
            },
          },
        },
        baseFormControl: {
          sx: {
            marginLeft: '8px',
          },
        },
        baseInputLabel: {
          sx: {
            transform: 'translate(14px, -9px) scale(0.75)',
          },
        },

        baseTextField: {
          variant: 'outlined',
        },
        baseSelect: {
          variant: 'outlined',
          notched: true,
        },
        // basePopper: {
        //   popperOptions: {
        //     placement: 'top-start',
        //   },
        // },

        pagination: {
          labelRowsPerPage: formatMessage({
            id: 'table.footer.row_per_page',
            defaultMessage: 'Rows per page',
          }),
          labelDisplayedRows: LabelDisplayedRows,
        },

        ...(props.slotProps || {}),
      }}
    />
  );
};
