import { Amplify } from 'aws-amplify';
import * as AWS from 'aws-sdk';
import { ManagedUpload } from 'aws-sdk/lib/s3/managed_upload';
import { AxiosError, AxiosResponse } from 'axios';
import moment from 'moment';

import { HttpService } from '@/Services';
import {
  AssetPut,
  AssetResponse,
  AssetsProps,
  GalleryAsset,
  LogoAssetProps,
  ResourceAuthDetail,
  VideoAsset,
  VideoAssetPut,
} from '@/Types';

export class AssetService {
  public httpService: HttpService;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
  }

  public async getAssetsByPartnerId(id?: string): Promise<AxiosResponse<AssetsProps> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();
    return await axiosInstance.get(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/assets`,
    );
  }

  public async deleteAsset(
    id: string,
    assetId: number,
  ): Promise<AxiosResponse<AssetsProps> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.delete(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/assets/${assetId}`,
    );
  }

  public async deleteAllAssets(id: string, assetIds: number[]) {
    return assetIds.map(assetId => this.deleteAsset(id, assetId));
  }

  public async updateLogoAsset(
    id: string,
    data: LogoAssetProps,
  ): Promise<AxiosResponse<AssetsProps> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxiosForPatchRequest();

    return await axiosInstance.patch(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/assets/logo`,
      data,
    );
  }

  public async assignLogo(
    id: string,
    data: AssetPut,
  ): Promise<AxiosResponse<AssetResponse> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.put(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/assets/logo`,
      data,
    );
  }

  public async assignGalleryImage(
    id: string,
    data: AssetPut,
  ): Promise<AxiosResponse<AssetResponse> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.put(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/assets/gallery`,
      data,
    );
  }

  public async updateGalleryAsset(
    id: string,
    data: { gallery: GalleryAsset[] },
  ): Promise<AxiosResponse<AssetsProps> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxiosForPatchRequest();

    return await axiosInstance.patch(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/assets/gallery`,
      data,
    );
  }

  public async updateVideoAsset(
    id: string,
    data: VideoAsset,
  ): Promise<AxiosResponse<any> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxiosForPatchRequest();

    return await axiosInstance.patch(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/assets/studiovideo`,
      data,
    );
  }

  public async assignVideo(
    id: string,
    data: VideoAssetPut,
  ): Promise<AxiosResponse<AssetResponse> & AxiosError> {
    const axiosInstance = await this.httpService.getAuthenticatedAxios();

    return await axiosInstance.put(
      `${process.env.REACT_APP_PIMCORE_ENDPOINT}api/partners/${id}/assets/studiovideo`,
      data,
    );
  }

  public getBasePath(id: string): string {
    return `assets/Partner/${id}/`;
  }

  public async getResourceAuthFromRemote(
    publicId: string,
    key: string,
  ): Promise<ResourceAuthDetail> {
    const claim = 'partnerAssetsS3';
    const authenticatedAxios = await this.httpService.getAuthenticatedAxios();

    if (key === 'ResourceAuth') {
      return (
        await authenticatedAxios.get(
          `${process.env.REACT_APP_USER_MANAGEMENT_ENDPOINT}resourceAuth/${claim}/${publicId}`,
        )
      ).data.detail;
    }

    const data = (
      await authenticatedAxios.get(
        `${process.env.REACT_APP_USER_ACCESS_ENDPOINT}resourceAuth/${claim}/${publicId}`,
      )
    ).data;

    let s3Data: ResourceAuthDetail = {
      AccessKeyId: '',
      SecretAccessKey: '',
      SessionToken: '',
      Expiration: '',
    };

    Object.keys(data).forEach(item => {
      if (item.includes(key)) {
        s3Data = { ...data[item] };
      }
    });
    return s3Data;
  }

  /**
   * Check if resource credentials are invalid with a safety buffer of 1 minute
   * @param currentDate
   * @param expireDate
   */
  public isResourceAuthExpired(currentDate: Date, expireDate: string): boolean {
    const expireMomentDate = moment(expireDate);
    const currentMomentDate = moment(currentDate).add('1', 'minute');
    return moment(currentMomentDate).isAfter(expireMomentDate, 'seconds');
  }

  public async getResourceAuth(publicId: string, key?: string): Promise<ResourceAuthDetail> {
    if (!key) {
      key = 'ResourceAuth';
    }
    const resourceAuthFromSessionStorage = sessionStorage.getItem(key);

    if (!!resourceAuthFromSessionStorage) {
      const parsedResourceAuthFromSessionStorage: ResourceAuthDetail = JSON.parse(
        resourceAuthFromSessionStorage,
      );
      if (this.isResourceAuthExpired(new Date(), parsedResourceAuthFromSessionStorage.Expiration)) {
        const response = await this.getResourceAuthFromRemote(publicId, key);
        sessionStorage.setItem(key, JSON.stringify(response));
        return Promise.resolve(response);
      } else {
        return Promise.resolve(parsedResourceAuthFromSessionStorage);
      }
    } else {
      const response = await this.getResourceAuthFromRemote(publicId, key);
      sessionStorage.setItem(key, JSON.stringify(response));
      return Promise.resolve(response);
    }
  }

  public getObjectPath(publicId: string, filename: string, folder: string): string {
    return `assets/Partner/${publicId}/${folder}/${filename}`;
  }

  public async configureAWS(publicId: string, key?: string) {
    const credentials = await this.getResourceAuth(publicId, key);

    AWS.config.update({
      region: 'eu-central-1',
      accessKeyId: credentials.AccessKeyId,
      secretAccessKey: credentials.SecretAccessKey,
      sessionToken: credentials.SessionToken,
    });
  }

  public async S3GetSignedResource(
    publicId: string,
    filename: string,
    folder: string,
  ): Promise<string> {
    try {
      await this.configureAWS(publicId);
      const config = Amplify.configure();
      const s3 = new AWS.S3();
      const key = this.getObjectPath(publicId, filename, folder);

      const signedUrl = s3.getSignedUrl('getObject', {
        // @ts-ignore
        Bucket: config.aws_user_files_s3_bucket,
        Key: key,
      });

      return Promise.resolve(signedUrl);
    } catch (e) {
      return Promise.reject('');
    }
  }

  public async S3GetObject(
    publicId: string,
    filename: string,
    folder: string,
    responseContentType?: string,
  ): Promise<any> {
    try {
      let storageKey = undefined;
      if (!folder.includes('Gallery')) {
        storageKey = folder;
      }
      await this.configureAWS(publicId, storageKey);
      const s3 = new AWS.S3();
      const config = Amplify.configure();
      if (storageKey) {
        const resp = await s3
          .getObject({
            // @ts-ignore
            Bucket: config.aws_user_files_s3_bucket,
            Key: `${folder}/${filename}`,
            ResponseContentType: responseContentType,
          })
          .promise();
        return resp;
      }

      const key = this.getObjectPath(publicId, filename, folder);
      const s3Object = await s3
        .getObject({
          // @ts-ignore
          Bucket: config.aws_user_files_s3_bucket,
          Key: key,
        })
        .promise();

      return Promise.resolve(s3Object);
    } catch (e) {
      return Promise.reject(undefined);
    }
  }

  public async S3Upload(
    publicId: string,
    filename: string,
    folder: string,
    file: File,
    progressCallback: (progress: ManagedUpload.Progress) => void,
    sendCallback: (err: any, data: any) => void,
  ): Promise<ManagedUpload> {
    try {
      await this.configureAWS(publicId);
      const s3 = new AWS.S3();
      const config = Amplify.configure();
      const key = this.getObjectPath(publicId, filename, folder);
      const upload = s3.upload({
        // @ts-ignore
        Bucket: config.aws_user_files_s3_bucket,
        Key: key,
        ContentType: file.type,
        Body: file,
      });

      upload.on('httpUploadProgress', progressCallback);
      upload.send(sendCallback);
      return upload;
    } catch (e) {
      throw new Error('Upload failed');
    }
  }

  public async DownloadFile(url: string) {
    try {
      const fileResponse = await fetch(url);
      const file = await fileResponse.blob();
      return file;
    } catch (e) {
      return Promise.reject(undefined);
    }
  }
}
