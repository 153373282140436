import 'react-image-gallery/styles/css/image-gallery.css';

import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { Box, Button } from '@mui/material';
import { useRef } from 'react';
import ImageGallery from 'react-image-gallery';
import { useIntl } from 'react-intl';

import theme from '@/App/Theme/Theme';
import { useAppDispatch } from '@/Hooks/useAppDispatch';
import BackgroundIcon from '@/Static/Icons/advertising_background_geo.svg';
import QrIcon from '@/Static/Icons/atoms-icon-qr.svg';
import HansefitLogo from '@/Static/Icons/hansefit_rgb.svg';
import QrPicture from '@/Static/Img/HANSEFIT_Partnerportal.png';
import FramePicture from '@/Static/Img/Instagram_post_-_103_small.png';
import BannerPartnerSurvay from '@/Static/Img/partner-banner-bg.png';
import TrainImage from '@/Static/Img/train-image.png';
import { uiActions } from '@/Store';

import FigmaDesignTokens from '../../design/design-tokens.json';
import { DisplayTextAccent } from '../Typography';
import AdvertismentHeaderWrapper from './AdvertismentHeader.styles';

const AdvertismentHeader = () => {
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const galleryRef = useRef<ImageGallery | null>(null);

  const showModal = () => {
    dispatch(
      uiActions.showModal('ADVERTISMENT_EXPLORER_FILTERS', {
        header: formatMessage({
          id: 'advertising_social_media.modal.title',
          defaultMessage: 'FILTER FOR SOCIAL MEDIA MARKETING',
        }),
        children: (
          <>
            <p
              style={{
                marginTop: '18px',
              }}>
              {formatMessage({
                id: 'advertising_social_media.modal.description',
                defaultMessage: 'Select your image, then your filter and share it on social media.',
              })}
            </p>
            <Box
              sx={{
                width: '540px',
                height: '272px',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '16px',
                display: 'inline-flex',
                marginTop: '36px',
              }}>
              <Box
                sx={{
                  width: '262px',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '12px',
                  display: 'inline-flex',
                }}>
                <Box
                  sx={{
                    alignSelf: 'stretch',
                    height: '224px',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: '8px',
                    display: 'flex',
                  }}>
                  <DisplayTextAccent
                    textContent={formatMessage({
                      id: 'advertising_social_media.label.download_desktop',
                      defaultMessage: 'For desktop',
                    })}
                  />

                  <Box
                    sx={{
                      borderRadius: '4px',
                      overflow: 'hidden',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      display: 'inline-flex',
                    }}>
                    <img
                      style={{ width: 'auto', height: '231px', borderRadius: 4 }}
                      src={FramePicture}
                      alt=''
                    />
                  </Box>
                </Box>

                <Button
                  variant='contained'
                  color='inherit'
                  sx={{
                    padding: '8px 16px',
                    background: FigmaDesignTokens.White,
                    borderRadius: 9999,
                    border: `1px ${theme.palette.primary.main} solid`,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9,
                    boxShadow: 0,
                  }}
                  href='https://livedab.cc/campaign/xtRLMRE'
                  target='_blank'>
                  <Box
                    sx={{
                      color: theme.palette.primary.main,
                      fontSize: '16px',
                      fontFamily: 'Roboto',
                      fontWeight: '500',
                      lineHeight: '20px',
                      wordWrap: 'break-word',
                    }}>
                    {formatMessage({
                      id: 'advertising_social_media.download_button_desktop.label',
                      defaultMessage: 'Get for desktop',
                    })}
                  </Box>
                </Button>
              </Box>
              <Box
                sx={{
                  width: '262px',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '12px',
                  display: 'inline-flex',
                }}>
                <Box
                  sx={{
                    alignSelf: 'stretch',
                    height: '224px',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: '8px',
                    display: 'flex',
                  }}>
                  <DisplayTextAccent
                    textContent={formatMessage({
                      id: 'advertising_social_media.label.download_mobile',
                      defaultMessage: 'For mobile',
                    })}
                  />

                  <img style={{ width: '200px', height: '200px' }} src={QrPicture} alt='' />
                </Box>
                <Box
                  sx={{
                    alignSelf: 'stretch',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    gap: '4px',
                    display: 'inline-flex',
                  }}>
                  <Box
                    sx={{
                      width: '24px',
                      height: '24px',
                      padding: '2px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                    }}>
                    <img style={{ width: '20px', height: '20px' }} src={QrIcon} alt=''></img>
                  </Box>
                  <Box
                    sx={{
                      flex: '1 1 0',
                      color: theme.palette.secondary.main,
                      fontSize: '16px',
                      fontFamily: 'Roboto',
                      fontWeight: '400',
                      lineHeight: '24px',
                      wordWrap: 'break-word',
                    }}>
                    {formatMessage({
                      id: 'advertising_social_media.label.download_mobile',
                      defaultMessage: 'Scan to download for mobile',
                    })}
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ),
      }),
    );
  };

  const images = [
    {
      original: '',
      renderItem: () => (
        <Button
          variant='contained'
          color='inherit'
          sx={{
            height: 139,
            position: 'relative',
            background: theme.palette.primary.main,
            overflow: 'hidden',
            width: '100%',
            borderRadius: '9px',
            boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
            '&:hover': {
              background: theme.palette.primary.main,
              boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
            },
          }}
          onClick={showModal}>
          <Box
            sx={{
              padding: '8px 16px',
              left: 372,
              top: 79,
              position: 'absolute',
              background: FigmaDesignTokens.White,
              borderRadius: 9999,
              border: `1px ${theme.palette.primary.main} solid`,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9,
              boxShadow: 0,
            }}>
            <Box
              sx={{
                color: theme.palette.primary.main,
                fontSize: 16,
                fontFamily: 'Roboto',
                fontWeight: '500',
                wordWrap: 'break-word',
              }}>
              {formatMessage({
                id: 'advertising_social_media.explore_button.label',
                defaultMessage: 'Explore filters',
              })}
            </Box>
          </Box>
          <img
            style={{ width: 348, left: -0, top: 0, position: 'absolute' }}
            src={TrainImage}
            alt='Header Train'
          />
          <img
            style={{ width: 308, right: 0, top: 0, position: 'absolute' }}
            src={BackgroundIcon}
            alt='Background Geometry'
          />
          <Box
            sx={{
              width: 348,
              height: 69,
              left: 0,
              top: 70,
              position: 'absolute',
              background: `linear-gradient(180deg, rgba(20, 62, 168, 0) 0%, ${theme.palette.primary.main} 95%)`,
            }}
          />
          <Box
            sx={{
              width: 592,
              left: 322,
              top: 24,
              position: 'absolute',
              color: FigmaDesignTokens.White,
              fontSize: 26,
              fontFamily: 'Roboto',
              fontWeight: '600',
              lineHeight: '39px',
              wordWrap: 'break-word',
            }}>
            {formatMessage({
              id: 'advertising_social_media.header',
              defaultMessage: 'Hansefit Filter for Social Media Marketing',
            })}
          </Box>

          <Box
            sx={{
              left: 16,
              top: 103,
              position: 'absolute',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: 24,
              display: 'inline-flex',
            }}>
            <Box sx={{ width: 109, height: 24, position: 'relative' }}>
              <img alt='' src={HansefitLogo} />
            </Box>
          </Box>

          <Box
            sx={{
              height: 30,
              width: 140,
              left: -28,
              top: 55,
              position: 'absolute',
              transform: 'rotate(-35.71deg)',
              transformOrigin: '0 0',
              background: theme.palette.success.main,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}>
            <Box
              sx={{
                color: FigmaDesignTokens.White,
                fontSize: 22,
                fontFamily: 'Roboto',
                fontWeight: '700',
                textTransform: 'uppercase',
                letterSpacing: 1,
                wordWrap: 'break-word',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                lineHeight: 1,
              }}>
              {formatMessage({
                id: 'advertising_social_media.new.label',
                defaultMessage: 'New',
              })}
            </Box>
          </Box>
        </Button>
      ),
    },
    {
      original: '',
      renderItem: () => (
        <Button
          variant='contained'
          color='inherit'
          sx={{
            height: 139,
            position: 'relative',
            background: theme.palette.primary.main,
            overflow: 'hidden',
            width: '100%',
            borderRadius: '9px',
            boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
            '&:hover': {
              background: theme.palette.primary.main,
              boxShadow: '0 20px 20px 0 rgba(0, 0, 0, 0.05)',
            },
          }}
          onClick={() => {
            window.open('https://hansefit.typeform.com/UmfrageVPP');
          }}>
          <Box
            sx={{
              padding: '8px 16px',
              left: 200,
              top: 72,
              position: 'absolute',
              background: FigmaDesignTokens.White,
              borderRadius: 9999,
              border: `1px ${theme.palette.primary.main} solid`,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 9,
              boxShadow: 0,
            }}>
            <Box
              sx={{
                color: theme.palette.primary.main,
                fontSize: 16,
                fontFamily: 'Roboto',
                fontWeight: '500',
                wordWrap: 'break-word',
                textTransform: 'capitalize',
              }}>
              {formatMessage({
                id: 'banner.survey.button.label',
                defaultMessage: 'Take a survey',
              })}
            </Box>
          </Box>
          <img alt='We value your partnership! What can we improve?' src={BannerPartnerSurvay} />
          <Box
            sx={{
              width: 592,
              left: 200,
              top: 24,
              position: 'absolute',
              color: FigmaDesignTokens.White,
              fontSize: 26,
              fontFamily: 'Roboto',
              fontWeight: '600',
              lineHeight: '39px',
              wordWrap: 'break-word',
            }}>
            {formatMessage({
              id: 'banner.survey.headline',
              defaultMessage: 'We value your partnership. What can we improve?',
            })}
          </Box>
        </Button>
      ),
    },
  ];

  return (
    <AdvertismentHeaderWrapper>
      <ImageGallery
        ref={galleryRef}
        showBullets
        autoPlay
        additionalClass='hf-gallery'
        items={images}
        slideInterval={10000}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
        isRTL={false}
        renderLeftNav={(onClick, disabled) => (
          <Button onClick={onClick} disabled={disabled} className='navigation left'>
            <ArrowBackIosOutlinedIcon />
          </Button>
        )}
        renderRightNav={(onClick, disabled) => (
          <Button onClick={onClick} disabled={disabled} className='navigation right'>
            <ArrowBackIosOutlinedIcon />
          </Button>
        )}
        onMouseOver={() => {
          if (galleryRef?.current) galleryRef.current.pause();
        }}
        onMouseLeave={() => {
          if (galleryRef?.current) galleryRef.current.play();
        }}
      />
    </AdvertismentHeaderWrapper>
  );
};

export default AdvertismentHeader;
